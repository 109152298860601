<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-04-07 10:26:37
 * @LastEditTime: 2021-05-12 15:55:08
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="mechanism-system">
    <LogoTitle msg="机构制度"></LogoTitle>
    <FileList></FileList>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  },
}
</script>

<style scoped lang='scss'>
</style>